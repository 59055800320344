.container {
  padding: 1rem;
  font-size: 75%;
}

@media (min-width: 800px) {
  .container {
    max-width: 1080px;
    margin: auto;
  }
}

.help {
  cursor: help;
}

.hover-gray:hover {
  background: rgb(224, 224, 224);
}

.bold {
  font-weight: bold;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-invalid {
  color: red;
}

.nowrap {
  white-space: nowrap;
}

.text-xxs {
  font-size: .55rem;
}